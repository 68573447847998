import React, { useState, useEffect } from "react";
import { reqInstance, apiurl } from "../globals";
import { useAppContext } from "../AppContext";
import { useNavigate } from "react-router-dom";
import Result from "./Result";

function SessionStatus(props) {
  const [sessionPlayers, setsessionPlayers] = useState([
    {
      id: 1,
      playerSeqNo: "",
      userName: "",
      userEmailId: "",
      score: "",
      isWinner: "",
    },
  ]);
  const { sessionDetails } = useAppContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (sessionPlayers.length > 0) {
      if (sessionPlayers.filter((e) => e.userEmailId === "").length === 0) {
        if (sessionDetails.id !== undefined) {
          //instead should have new endpoint is all ready with session
          reqInstance
            .get(`${apiurl}/api/sessionQuestions/${sessionDetails.id}/${1}`)
            .then((response) => {
              // Handle the API response

              navigate("/showquizandresults");
              //startQuiz(response.data,currentsessionuser.userEmailId,currentsessionuser.userName);
            })
            .catch((error) => {
              console.log("Error:", error);
            });
        }
      }
    }
  }, [sessionPlayers]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (sessionDetails.id !== undefined) {
          reqInstance
            .get(`${apiurl}/api/sessionusers/players/${sessionDetails.id}`)
            .then((response) => {
              // Handle the API response

              //atleast one player should be in this.
              setsessionPlayers(response.data);
              console.log("sessionPlayers", response.data);

              //if all players joined the session, start the quiz
              if (
                sessionPlayers.filter((e) => e.userEmailId === "").length === 0
              ) {
                console.log("all players joined the session");

                navigate("/showquizandresults");
              }
            })
            .catch((error) => {
              // Handle any error that occurred during the API call
              console.error("Error:", error);
            });
        }
      } catch (error) {
        // Handle any errors
        console.error(error);
      }
    };

    const interval = setInterval(() => {
      fetchData();
    }, 2000);

    // Clean up the interval on component unmount
    return () => clearInterval(interval);
  }, [sessionPlayers]);

  return (
    <section className="bg-dark text-white">
      <div className="container">
        <div className="row vh-100 align-items-center justify-content-center">
          <div className="col-lg-8">
            <h1 className="fw-bold mb-4"> Showing all the player names.</h1>
            <h1 className="fw-bold mb-4"> Waiting for others to join....</h1>
            <div>
              {sessionPlayers.map((player) => (
                <p btn key={player.id}>
                  Player {player.playerSeqNo} {player.userName}
                </p>
              ))}
            </div>
            {/* {currentsessionuser.userEmailId === sessiondetails.organizerEmailId && <button> Start with existing players </button> } */}
          </div>
        </div>
      </div>
    </section>
  );
}

export default SessionStatus;
