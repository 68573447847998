import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import { GoogleLogout } from "react-google-login";

const clientId =
  "356394307734-0576bulqkuvvtgtvbsr1v4mm24o1vcad.apps.googleusercontent.com";

const onSuccess = (res) => {
  console.log("Login Success Current User", res.profileObj);
  toast.success("Google Logged Success");
};

const Logout = (props) => {
  const [loginType, setLoginType] = useState("");
  useEffect(() => {
    setLoginType(localStorage.getItem("loginMethod"));
  }, []);

  const fbLogout = (e) => {
    props.handleFacebookLogout();
  };

  return (
    <>
      {loginType === "GOOGLE" ? (
        <div id="signOutButton">
          <GoogleLogout
            clientId={clientId}
            buttonText="Logout"
            onSuccess={onSuccess}
          />
        </div>
      ) : (
        <div id="signOutButton">
          <button onClick={fbLogout}>
            LogOut
          </button>
        </div>
      )}
    </>
  );
};

export default Logout;
