import React, { useEffect, useState } from "react";
import { reqInstance, apiurl } from "../globals";
import { useAppContext } from "../AppContext";
import { useNavigate } from "react-router-dom";
import MilitaryTechIcon from "@mui/icons-material/MilitaryTech";

const Result = ({ currentQuestion, showNextQuestion, showFinalScreen }) => {
  const { sessionDetails,currentsessionuser } = useAppContext();
  const [topscorers, setTopScoreres] = useState([]);
  const [showResult, setShowResult] = useState(true);
  const navigate = useNavigate();

  const getTopScoreData = () => {
    reqInstance
      .get(`${apiurl}/api/SessionUserScores/GetTopScorers/${sessionDetails.id}`)
      .then((response) => {
        
        setTopScoreres(response.data);
      })
      .catch((error) => {
        console.log("Error:", error);
      });
  };

  const gotoToMain = () => {
    navigate("/");
  };

  useEffect(() => {
    getTopScoreData();
    console.log("sess data",sessionDetails);
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        //If time is out or all players answered the questions then move to next question
        showNextQuestion();

        //const response = await axios.get('your-api-endpoint');
        // Process the response data as needed
        //console.log(response.data);
      } catch (error) {
        // Handle any errors
        console.error(error);
      }
    };

    const interval = setInterval(() => {
      fetchData();
    }, 9000);
    // Clean up the interval on component unmount
    return () => clearInterval(interval);
  }, [showResult]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        getTopScoreData();
      } catch (error) {
        // Handle any errors
        console.error(error);
      }
    };

    const interval = setInterval(() => {
      fetchData();
    }, 3000);
    // Clean up the interval on component unmount
    return () => clearInterval(interval);
  }, [showResult]);

  return topscorers.length > 0 &&
    currentQuestion <= sessionDetails.noOfQuestions ? (
    <section className="">
      <div className="container">
        <div className="row vh-100 align-items-center justify-content-center">
          <div className="col-lg-6">
            <div
              className={`text-light text-center p-5 rounded ${
                (1 * 5) / 2 ? "bg-success" : "bg-danger"
              }`}
            >
              <h1 className="mb-2 fw-bold">
                Here is the top 3 players after round {currentQuestion}{" "}
              </h1>
              {/* <h3 className='mb-3 fw-bold'>Your score is {marks} out of {quizs.length * 5}</h3> */}
              <div>
                {topscorers.map((topper) => (
                  <p btn key={topper.userId}>
                    {topper.displayName} {topper.totalScore}
                  </p>
                  
                ))}
              </div>
              {currentQuestion < sessionDetails.noOfQuestions &&
                (sessionDetails.noOfPlayers === 1 || sessionDetails.playSameTime === false) && (
                  <div>
                  <button
                    onClick={showNextQuestion}
                    className="btn py-2 px-4 btn-light fw-bold d-inline"
                  >
                    Next Question
                  </button>
                  </div>
                )}
              {currentQuestion < sessionDetails.noOfQuestions &&
                (sessionDetails.noOfPlayers > 1 && sessionDetails.playSameTime  ) && (
                  <label className="btn py-2 px-4 btn-light fw-bold d-inline">
                    Get Ready for Next Question!!!
                  </label>
                )}
              {currentQuestion === sessionDetails.noOfQuestions && (
                <>
                  <h2>
                    Reached last question. <br></br> Thank you for playing.
                  </h2>
                  <button onClick={gotoToMain}>Close</button>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  ) : null;
//   const mock = [
//     {
//       userId: "taimoor@gmail.com",
//       totalScore: 1966,
//       displayName: "venkat",
//     },
//     {
//       userId: "venkat@123.com",
//       totalScore: 0,
//       displayName: "Ali",
//     },
//     {
//       userId: "bala@123.com",
//       totalScore: 12,
//       displayName: null,
//     },
//   ];

//   return (
//     <section className="bg-dark text-white">
//       <div className="container">
//         <div className="row vh-100 align-items-center justify-content-center">
//           <div className="col-lg-6">
//             <div
//               className={`text-light text-center p-5 rounded ${
//                 (1 * 5) / 2 ? "bg-success" : "bg-danger"
//               }`}
//             >
//               <h1 className="mb-2 fw-bold">
//                 Here is the top 3 players after round "currentQuestion"
//               </h1>
//               {/* <h3 className='mb-3 fw-bold'>Your score is {marks} out of {quizs.length * 5}</h3> */}
//               <div>
//                 {mock.map((topper) => (
//                   <div
//                     className="d-flex justify-content-center"
//                     key={topper.userId}
//                     style={{
//                       display: "flex",
//                       justifyContent: "space-between",
//                       alignItems: "center",
//                       marginBottom: "10px",
//                     }}
//                   >
//                     <div style={{ display: "flex", alignItems: "center" }}>
//                       <p style={{ margin: "0 10px" }}>{topper.userId}</p>
//                       <p style={{ margin: "0 10px" }}>{topper.totalScore}</p>
//                     </div>

//                     {/* <div style={{ display: "flex", alignItems: "center" }}>
//                       {topper.totalScore === 1966 ? (
//                         <>
//                           <MilitaryTechIcon
//                             style={{
//                               color: "#FFD700",
//                               fontSize: "30px",
//                               marginRight: "10px",
//                             }}
//                           />
//                           <p
//                             style={{
//                               color: "#FFD700",
//                               fontSize: "18px",
//                               margin: "0",
//                             }}
//                           >
//                             Gold
//                           </p>
//                         </>
//                       ) : topper.totalScore === 12 ? (
//                         <>
//                           <MilitaryTechIcon
//                             style={{
//                               color: "#C0C0C0",
//                               fontSize: "30px",
//                               marginRight: "10px",
//                             }}
//                           />
//                           <p
//                             style={{
//                               color: "#C0C0C0",
//                               fontSize: "18px",
//                               margin: "0",
//                             }}
//                           >
//                             Silver
//                           </p>
//                         </>
//                       ) : topper.totalScore === 0 ? (
//                         <>
//                           <MilitaryTechIcon
//                             style={{
//                               color: "#CD7F32",
//                               fontSize: "30px",
//                               marginRight: "10px",
//                             }}
//                           />
//                           <p
//                             style={{
//                               color: "#CD7F32",
//                               fontSize: "18px",
//                               margin: "0",
//                             }}
//                           >
//                             Bronze
//                           </p>
//                         </>
//                       ) : null}
//                     </div> */}
//                   </div>
//                 ))}
//               </div>
//               {/* {currentQuestion < sessionDetails.noOfQuestions &&
//                 sessionDetails.noOfPlayers === 1 && (
//                   <button
//                     onClick={showNextQuestion}
//                     className="btn py-2 px-4 btn-light fw-bold d-inline"
//                   >
//                     Next Question
//                   </button>
//                 )} */}
//               {/* {currentQuestion < sessionDetails.noOfQuestions &&
//                 sessionDetails.noOfPlayers > 1 && (
//                   <label className="btn py-2 px-4 btn-light fw-bold d-inline">
//                     Get Ready for Next Question!!!
//                   </label>
//                 )} */}
//               {/* {currentQuestion === sessionDetails.noOfQuestions && (
//                 <>
//                   <h2>
//                     Reached last question. <br></br> Thank you for playing.
//                   </h2>
//                   <button onClick={gotoToMain}>Close</button>
//                 </>
//               )} */}
//             </div>
//           </div>
//         </div>
//       </div>
//     </section>
//   );
 };

export default Result;
