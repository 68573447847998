/* eslint-disable react/jsx-no-comment-textnodes */
import React from 'react';

function DeleteData() {
  return (
    <>
      {/* Ensure comments do not interfere with rendering */}
      {/* <div className="container"> */}
      <section>
        <div className="row vh-100 align-items-center justify-content-center">
          <div className="col-lg-8">
            <p>Here's a set of delete data terms for playlearnmist.com quiz website:</p>

            <p>---</p>

            <p>
              <strong>Terms of Use</strong>
            </p>

            <p>
              "At https://playlearnmist.com, we take data privacy seriously. We understand the importance of giving our users control over their personal information. That's why we offer a seamless data deletion process. If you wish to delete your account and associated data from our platform, simply navigate to three dots in the right top corner and select the option to delete your account, or by going to this link <a href="https://playlearnmist.com/deletemydata">https://playlearnmist.com/deletemydata</a>. Upon confirmation, all of your personal data will be permanently removed from our servers except the payment record. We are using Stripe payment, so the payment data is not stored on our site; we only store the transaction ID that we received from Stripe. This way, we ensure your privacy and peace of mind. Please note that this action is irreversible and will result in the loss of all account-related information, including quiz history and preferences. For more information about our data deletion policy, please refer to our Privacy Policy."
            </p>
          </div>
        </div>
        <noscript>You need to enable JavaScript to run this app.</noscript>
    <div id="root"></div>
    // eslint-disable-next-line react/jsx-no-comment-textnodes
    <script>
    var path = window.location.pathname;
    path = path[0] + "#" + path;
    var url = window.location.protocol + "//" + window.location.hostname + path;
    //document.write("Redirecting To: "+url);
    window.location.href=url;
    </script>
      {/* </div> */}
      </section>
    </>
  );
}

export default DeleteData;
