import React, { useEffect } from 'react'
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import {reqInstance, apiurl } from "../globals";

function MyVerticallyCenteredModal(props) {
    const {id,refresh,onHide} = props;
    
      const deleteSessionQuestion = async (id) => {
        try {
          const response = await reqInstance.delete(`${apiurl}/api/SessionQuestions/${id}`);
          refresh();
          onHide();

        } catch (error) {
          props.onHide();
          alert('something went wrong');
        }
      };
    return (
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            Confirm Delete
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>
            Are you want to delete it?
          </p>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={()=>{
            deleteSessionQuestion(id);
          }} className='btn-danger'>Delete</Button>
        </Modal.Footer>
      </Modal>
    );
  }

export default MyVerticallyCenteredModal;