// // globals.js
// export let currentsessionuser = "{id:1,userName:'test',userEmailId:'test@test'}";

// export const setCurrentSessionUser = (newValue) => {
//     currentsessionuser = newValue;   
// };
import axios from 'axios';
export let websiteurl = "https://playlearnmist.com";
export let apiurl = "https://api.playlearnmist.com";
export let debugMode = true;

// export let websiteurl = "https://localhost:3001";
// export let apiurl = "https://localhost:5000";
// export let debugMode = true;

// export let apiurl = "https://ec2-34-226-147-42.compute-1.amazonaws.com";


export let STRIPE_KEY ='pk_live_51OCYGaGJVWLloJD8fqik0RTc6Wj41WL88VOJwpl1P5FTWuvLcrCpFoBbMSuJKZjrXCNrVNUYhFpME9MR4YTxP1pD00St2D61BH';
//export let JWT_TOKEN='eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwianRpIjoiZTYzYjhjOWQtYTg5Yy00M2I1LThjNmMtNmFhYjU3ODU5OGUxIiwibmJmIjoxNzIyOTExMjc4LCJleHAiOjc2NjI5MTEyNzgsImlhdCI6MTcyMjkxMTI3OCwiaXNzIjoiY2xsYWllbmdpbmUiLCJhdWQiOiJsZWFybm1pc3F1aXp1c2VycyJ9.pplQEMx6iqfSCdAIRJd3TlTSVY0zZpu-5Gd6OIPXXCU'
export let reqInstance = axios.create({    
        headers: {          
          'ed9f22d964ca49c7b7a0c73da60b63e3': 'C39219DF-DA93-4837-992C-7A40DA1E0A14',
          'Authorization':'Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJzdWIiOiIxMjM0NTY3ODkwIiwianRpIjoiZTYzYjhjOWQtYTg5Yy00M2I1LThjNmMtNmFhYjU3ODU5OGUxIiwibmJmIjoxNzIyOTExMjc4LCJleHAiOjc2NjI5MTEyNzgsImlhdCI6MTcyMjkxMTI3OCwiaXNzIjoiY2xsYWllbmdpbmUiLCJhdWQiOiJsZWFybm1pc3F1aXp1c2VycyJ9.pplQEMx6iqfSCdAIRJd3TlTSVY0zZpu-5Gd6OIPXXCU',
          withCredentials: true
        }

    // headers: {
    //   Authorization : `Bearer ${localStorage.getItem("access_token")}`
    //   }
    }
  );