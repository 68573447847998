import React, { useState,useEffect } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { apiurl, reqInstance } from "../globals";

const ModalEditQuestion = ({show,handleClose,id,question,choice1,choice2,choice3,choice4,answerOption,sessionId,refresh}) => {
  const [priorQuestion,setPriorQuestion] = useState(question);
  const [priorOptions,setPriorOptions] = useState({choice1,choice2,choice3,choice4});
  const [priorAnsChoice,setPriorAnsChoice] = useState(answerOption);
  useEffect(() => {
    console.log(sessionId)
    console.log('the edit script has been open');
  }, [])
  
  function submitForm(event){
    event.preventDefault();
    const updatedData = {
      id,
      sessionId,
      question:priorQuestion,
      ...priorOptions,
      questionType: 'string',
    "sequenceNo": 0,
    answerOption:priorAnsChoice
    }
    console.log("updated data ",updatedData);
    // api for edit question
    reqInstance.put(apiurl+'/api/SessionQuestions/'+id, updatedData)
    .then((response) => {
      console.log('data has been updated on server',response.data)
      refresh();
      handleClose();
    })
    .catch((error) => {
      console.log(error);
    });
    
  }
    return (
        <Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>Edit question</Modal.Title>
        </Modal.Header>
        <form onSubmit={(e)=>{
          submitForm(e);
        }}>
        <Modal.Body>
        {
          //edit question
        }
        <div className="form-group">
            <textarea
              type="text"
              className="form-control"
              id="question"
              placeholder="Enter question"
              name="question"
              required
              onChange={(e) => {
                  setPriorQuestion(e.currentTarget.value);
              }}
              value={priorQuestion}
            />
          </div>
          {
            //edit option one
          }
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              id="option-one"
              placeholder="option-1"
              name="option-one"
              onChange={(e) => {
                setPriorOptions({...priorOptions,choice1:e.currentTarget.value})
              }}
              required
              value={priorOptions.choice1}
            />
          </div>
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              id="option-two"
              placeholder="option-2"
              name="option-two"
              onChange={(e) => {
                setPriorOptions({...priorOptions,choice2:e.currentTarget.value})
              }}
              required
              value={priorOptions.choice2}
            />
          </div>
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              id="option-three"
              placeholder="option-3"
              name="option-three"
              onChange={(e) => {
                setPriorOptions({...priorOptions,choice3:e.currentTarget.value})
              }}
              required
              value={priorOptions.choice3}
            />
          </div>
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              id="option-four"
              placeholder="option-4"
              name="option-four"
              onChange={(e) => {
                setPriorOptions({...priorOptions,choice4:e.currentTarget.value})
              }}
              required
              value={priorOptions.choice4}
            />
          </div>
          <Form.Select
            required
            onChange={(e) =>{
              setPriorAnsChoice(e.currentTarget.value);
            }
            }
            defaultValue={priorAnsChoice}
          >
            <option value="1">One</option>
            <option value="2">Two</option>
            <option value="3">Three</option>
            <option value="4">Four</option>
          </Form.Select>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          <Button variant="primary" type='submit'>
            Save Changes
          </Button>
        </Modal.Footer>
        </form>
      </Modal>
    )
}
export default ModalEditQuestion;