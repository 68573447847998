import React, { useEffect } from "react";
import { useAppContext } from "../AppContext";
import { useLocation, useNavigate } from "react-router-dom";
import { reqInstance, apiurl } from "../globals";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";

const JoinQuizSession = () => {
  const [sessionID, setSessionID] = React.useState("");
  const [name, setName] = React.useState("");
  const [useremailid, setUserEmailId] = React.useState("");
  const [defaultEmail, setDefaultEmail] = React.useState(localStorage.getItem("emailId")||'');
  const { updateSessionDetails, updateCurrentSessionUser} = useAppContext();
  const navigate = useNavigate();

  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const randomCode = searchParams.get("sessionid");

  console.log("Random Code in OtherComponent:", randomCode);

  // Default Value Set in Yup
  const defaultValues = {
    // SessionCode: "comm",
    SessionCode: randomCode,
    // userName: name,
    // userEmailId: useremailid,
  };
  // Form Validation
  const createSessionValidation = yup.object().shape({
    SessionCode: yup
      .string()
      .min(4)
      .max(4)
      .required("Enter 4 char Session code sent by admin"),
    userName: yup.string().required("Enter your name to display in score"),
    userEmailId: yup
      .string()
      .email()
      .required("Please Enter Organizer Email ID!"),
  });

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm({
    resolver: yupResolver(createSessionValidation),
    defaultValues: defaultValues,
  });

  //* This work is Panding
  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    for (let [key, value] of urlParams.entries()) {
      if (key.toString().toLowerCase() === "sessionid") setSessionID(value);
    }
  }, []);

  // const handleSessionIdChange = (e) => {
  //   setSessionID(e.target.value);
  // };

  // const handleNameChange = (e) => {
  //   setName(e.target.value);
  // };

  // const handleUserEmailIdChange = (e) => {
  //   setUserEmailId(e.target.value);
  // };

  const onSubmit = (event) => {
    console.log("My event data", event.SessionCode);
    setSessionID(event.SessionCode);
    
    let currentuser = {
      userEmailId: event.userEmailId,
      userName: event.userName,
    };
    
    // currentuser.userEmailId = useremailid;
    // currentuser.name = name;
    updateCurrentSessionUser(currentuser);
    
    reqInstance
      .get(
        `${apiurl}/api/SessionQuestions/InitiateQuiz/${event.SessionCode}/${event.userEmailId}/${event.userName}`
      )
      .then((response) => {
        updateSessionDetails(response.data);

        navigate("/sessionstatus");
        //startQuiz(response.data,useremailid,name);
      })
      .catch((error) => {
        // Handle any error that occurred during the API call
        alert("Sorry error occured." + error.message);
        console.error("Error:", error);
      });
  };
  // const initiateQuiz = () => {
  //   let currentuser = {
  //     userEmailId: "",
  //     userName: "",
  //   };
  //   currentuser.userEmailId = useremailid;
  //   currentuser.name = name;
  //   updateCurrentSessionUser(currentuser);

  //   reqInstance
  //     .get(
  //       `${apiurl}/api/SessionQuestions/InitiateQuiz/${sessionID}/${useremailid}/${name}`
  //     )
  //     .then((response) => {
  //       updateSessionDetails(response.data);

  //       navigate("/sessionstatus");
  //       //startQuiz(response.data,useremailid,name);
  //     })
  //     .catch((error) => {
  //       // Handle any error that occurred during the API call
  //       alert("Maximum no of allwed players reached.");
  //       console.error("Error:", error);
  //     });
  // };

  return (
    <>
      <section
        className="d-flex align-items-center"
        style={{ display: `${true ? "block" : "none"}`, minHeight: "100vh" }}
      >
        <div className="container">
          <div className="row p-0 m-0 align-items-center justify-content-center">
            <div className="col-lg-8">
              <h2 className="fw-bold mb-4 text-center">
                Join existing quiz session
              </h2>
              <form onClick={handleSubmit(onSubmit)}>
                <div className="row m-0 p-0 justify-content-center">
                  <div className="col-md-8">
                    <Controller
                      name="SessionCode"
                      control={control}
                      defaultValue={defaultValues.SessionCode}
                      render={({ field }) => (
                        <input
                          type="text"
                          className="form-control"
                          maxLength="4"
                          placeholder="Enter 4 char Session code sent by admin"
                          {...field}
                        />
                      )}
                    />
                    {errors.SessionCode && (
                      <p style={{ color: "#dc3545" }} className="p-0 m-0 mt-1">
                        {errors.SessionCode.message}
                      </p>
                    )}
                  </div>

                  <div className="col-md-8 mt-3">
                    <Controller
                      name="userName"
                      control={control}
                      render={({ field }) => (
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter your name to display in score"
                          {...field}
                        />
                      )}
                    />
                    {errors.userName && (
                      <p style={{ color: "#dc3545" }} className="p-0 m-0 mt-1">
                        {errors.userName.message}
                      </p>
                    )}
                  </div>

                  <div className="col-md-8 mt-3">
                    <Controller
                      name="userEmailId"
                      control={control}
                      defaultValue={defaultEmail} 
                      render={({ field }) => (
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Enter your EmailId"
                          {...field}
                        />
                      )}
                    />
                    {errors.userEmailId && (
                      <p style={{ color: "#dc3545" }} className="p-0 m-0 mt-1">
                        {errors.userEmailId.message}
                      </p>
                    )}
                  </div>
                </div>

                {/* <input
                value={formData.SessionName}
                onChange={(e) => handleInputChange(e, "SessionName")}
                type="text"
                className="form-control mb-4"
                placeholder="Enter Session Name"
              />
              <input
                value={formData.NoOfQuestions}
                onChange={(e) => handleInputChange(e, "NoOfQuestions")}
                type="text"
                className="form-control mb-4"
                placeholder="Enter No of Questions. Max (10)"
              />
              <input
                value={formData.NoOfPlayers}
                onChange={(e) => handleInputChange(e, "NoOfPlayers")}
                type="text"
                className="form-control mb-4"
                placeholder="Enter No of Players"
              />
              <input
                value={formData.OrganizerEmail}
                onChange={(e) => handleInputChange(e, "OrganizerEmailId")}
                type="text"
                className="form-control mb-4"
                placeholder="Enter Organizer Email ID"
              />
              <input
                value={formData.OrganizerPhone}
                onChange={(e) => handleInputChange(e, "OrganizerPhone")}
                type="text"
                className="form-control mb-4"
                placeholder="Enter Organizer Phone Number. (optional)"
              />
              <input
                value={formData.Description}
                onChange={(e) => handleInputChange(e, "Description")}
                type="text"
                className="form-control mb-4"
                placeholder="Describe in 200 words what kind of questions you need to AI Quiz generator."
              /> */}

                {/* <SessionCategory></SessionCategory> */}
                {/* <div className="col-lg-8 text-center mx-auto">
                  <label className="break-after-all mt-2 mb-2">
                    Session Code for all players to join this session is: &nbsp;
                    <span
                      className="bold"
                      onBlur={(e) => handleInputChange(e, "SessionCode")}
                      suppressContentEditableWarning
                    >
                      <span className="bg-success rounded p-1">
                        {randomCode}
                      </span>
                    </span>
                  </label>
                </div> */}
                <br />
                {/* {sessionCreated && (
                  <>
                    <label className="bg-success text-center">
                      {" "}
                      Your session Created Successfully{" "}
                    </label>
                    <button onClick={joinSessionNewWindow}>join Quiz</button>
                    <button>Close</button>
                  </>
                )}
                {errorOccured && (
                  <div className="d-flex justify-content-center">
                    <div className="bg-danger p-2 rounded text-center">
                      Error while creating the questions
                      <button
                        onClick={handleRetry}
                        className="break-after-all btn ml-1 px-4 py-2 bg-light text-dark fw-bold ms-2"
                      >
                        Try Again
                      </button>
                    </div>
                  </div>
                )}

                {preparingQuestions && (
                  <label fw-bold> AI in work please wait .... </label>
                )}
                {createSession && (
                  <div className="text-center">
                    <button
                      type="submit"
                      className="break-after-all btn btn-primary px-4 py-2 fw-bold"
                    >
                      Create this Quiz Session
                    </button>
                  </div>
                )} */}

                <div className="text-center">
                  <button
                    type="submit"
                    // onClick={initiateQuiz}
                    className="btn btn-primary px-4 py-2 fw-bold"
                  >
                    Join Quiz
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>

      {/* 888888888888888888888 */}
      {/* <section className="text-white text-center bg-dark">
        <div className="container">
          <div className="row vh-100 align-items-center justify-content-center">
            <div className="col-lg-8">
              <h1 className="fw-bold mb-4">Join existing quiz session </h1> */}

      {/* // add a input so user can enter a session id to join a Quiz session
       */}
      {/* <input
                value={sessionID}
                onChange={handleSessionIdChange}
                type="text"
                className="form-control mb-4"
                placeholder="Enter 4 char Session code sent by admin"
              />

              <input
                value={name}
                onChange={handleNameChange}
                type="text"
                className="form-control mb-4"
                placeholder="Enter your name to display in score "
              />
              <input
                value={useremailid}
                onChange={handleUserEmailIdChange}
                type="text"
                className="form-control mb-4"
                placeholder="Enter your EmailId"
              /> */}
      {/* <button
                onClick={initiateQuiz}
                className="btn px-4 py-2 bg-light text-dark fw-bold"
              >
                Join Quiz
              </button> */}
      {/* </div>
          </div>
        </div>
      </section> */}
    </>
  );
};

export default JoinQuizSession;
