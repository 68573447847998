
function PrivacyPolicy() {

  return (
    <section >
      {/* <div className="container"> */}
        <div className="row align-items-center justify-content-center">
          <div className="col-lg-8">
          <p><strong>Privacy Policy</strong></p>

<p>This Privacy Policy describes how&nbsp; <strong>Cloud Logic Labs Inc</strong></p>

<p>&nbsp;(&quot;we,&quot; &quot;us,&quot; or &quot;our&quot;) collects, uses, and discloses information, including personal information,</p>

<p>&nbsp;that we obtain through PlayLearnMist.com (the &quot;Site&quot;).</p>

<p><strong>1. Information We Collect</strong></p>

<ul>
	<li>Personal Information We may collect personal information that you voluntarily provide to us when you use the Site.</li>
	<li>This may include your name, email address, and any other information you provide when using the Site.</li>
</ul>

<p><strong>Usage Data</strong></p>

<ul>
	<li>We may automatically collect certain information when you visit the Site, such as your IP address, browser type, operating system, referring URLs, pages viewed, and other usage information.</li>
</ul>

<p><strong>2. Use of Information</strong></p>

<ul>
	<li>We may use the information we collect, including personal information, to:</li>
	<li>Provide, operate, and improve the Site;</li>
	<li>Respond to your inquiries and fulfill your requests;</li>
	<li>Send you administrative information, such as updates, security alerts, and support and administrative messages;</li>
	<li>Communicate with you about products, services, offers, promotions, and events offered by us;</li>
	<li>Detect, investigate, and prevent fraudulent transactions and other illegal activities;</li>
	<li>Comply with applicable laws, regulations, and legal processes.</li>
	<li>We may use cookies and similar technologies to collect information about your browsing activities over time and across different websites.</li>
</ul>

<p><strong>3. Disclosure of Information</strong></p>

<ul>
	<li>a. We may disclose personal information:</li>
	<li>b.To our affiliates and service providers who perform services on our behalf</li>
	<li>c.To comply with legal obligations, including to respond to lawful requests and legal processes;</li>
	<li>d. To protect and defend our rights, interests, or property.</li>
	<li>e. We may also disclose aggregated and anonymized information that does not identify any individual.</li>
</ul>

<p><strong>4. Data Security</strong></p>

<ul>
	<li>We take reasonable measures to protect the information we collect from unauthorized access, disclosure, alteration, or destruction.</li>
</ul>

<p><strong>5. Your Choices</strong></p>

<ul>
	<li>You may choose not to provide certain personal information, but this may limit your ability to use certain features of the Site.</li>
</ul>

<p><strong>6. Changes to this Privacy Policy</strong></p>

<ul>
	<li>We may update this Privacy Policy from time to time. The revised policy will be posted on this page with an updated revision date.</li>
</ul>

<p><strong>7. Contact Us</strong></p>

<ul>
	<li>If you have any questions about this Privacy Policy or our privacy practices, please contact us at <a href="mailto:koovent@gmail.com">koovent@gmail.com</a></li>
	<li>Remember to replace placeholders like&nbsp; Clouod Logic Labs Inc, https://Playlearnmist.com, and Venkat Bala Koovent@gmail.com with your actual details. Additionally, consider including specific details about how you handle user data and any applicable regulations you comply with. It&#39;s recommended to have a legal professional review your privacy policy to ensure compliance with relevant laws and regulations.</li>
</ul>

<p>&nbsp;</p>

<p>&nbsp; &nbsp; &nbsp; &nbsp;</p>

        </div>
      </div>
      {/* </div> */}
    </section>
  );
}

export default PrivacyPolicy;
