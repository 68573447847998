import React, { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import { v4 as uuidv4 } from "uuid";
import { apiurl, reqInstance } from "../globals";
const ModalAddQuestion = ({ show, handleClose, sessionId,refresh}) => {
  const uuid = uuidv4();
  const [question, setQuestion] = useState("");
  const [options, setOptions] = useState({});
  function addQuestion(event) {
    // method for adding question when submit is hit

    event.preventDefault();

    const singleQuestion = {
      ...options,
      id: uuid,
      sessionId,
      question,
      questionType: 'string',
      "sequenceNo": 0,
    };
    // added question object
    console.log(singleQuestion);
    // send post request to server
    reqInstance.post(apiurl+'/api/SessionQuestions', singleQuestion)
    .then((response) => {
      console.log('api response',response)
      console.log("question has added successfully on server")
      refresh();
      handleClose();
    })
    .catch((error) => {
        console.log(error);
    });
  }
  return (
    <Modal show={show} onHide={handleClose}>
      <Modal.Header closeButton>
        <Modal.Title>add another question</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form id="add-ques" onSubmit={(e)=>addQuestion(e)}>
          <div className="form-group">
            <textarea
              type="text"
              className="form-control"
              id="question"
              placeholder="Enter question"
              name="question"
              required
              onChange={(e) => setQuestion(e.currentTarget.value)}
            />
          </div>
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              id="option-one"
              placeholder="option-1"
              name="option-one"
              onChange={(e) => {
                const value = e.currentTarget.value;
                setOptions((curr) => {
                  return { ...curr, choice1: value };
                });
              }}
              required
            />
          </div>
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              id="option-two"
              placeholder="option-2"
              name="option-two"
              onChange={(e) => {
                const value = e.currentTarget.value;
                setOptions((curr) => {
                  return { ...curr, choice2: value };
                });
              }}
              required
            />
          </div>
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              id="option-three"
              placeholder="option-3"
              name="option-three"
              onChange={(e) => {
                const value = e.currentTarget.value;
                setOptions((curr) => {
                  return { ...curr, choice3: value };
                });
              }}
              required
            />
          </div>
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              id="option-four"
              placeholder="option-4"
              name="option-four"
              onChange={(e) => {
                const value = e.currentTarget.value;
                setOptions((curr) => {
                  return { ...curr, choice4: value };
                });
              }}
              required
            />
          </div>
          <Form.Select
            required
            onChange={(e) =>{
              const value = e.currentTarget.value;
              console.log('answer',value);
              setOptions((curr)=>{return { ...curr, answerOption:value }})
            }
            }
          >
            <option disabled selected value=''>
              select answer
            </option>
            <option value="1">One</option>
            <option value="2">Two</option>
            <option value="3">Three</option>
            <option value="4">Four</option>
          </Form.Select>
          <button
            type="submit"
            className="btn btn-primary my-1"
          >
            Submit
          </button>
        </form>
      </Modal.Body>
    </Modal>
  );
};

export default ModalAddQuestion;
