import React, { useEffect, useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import "../css/previewQue.css";
import Pencil from "../images/pencil.svg";
import Trash from "../images/trash.svg";
import { useReactToPrint } from "react-to-print";
import ConfirmDelete from "./ConfirmDeleteModal";
import { apiurl, reqInstance } from "../globals";
import AddModal from './modalAddQuestion';
import EditModal from './modalEditQuestion';
const PreviewQuestions = () => {
  //clean up function
  const printable = useRef();
  const handlePrint = useReactToPrint({
    content: () => printable.current,
  });
  const [modalDelete, setModalDelete] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [sessionCode,setSessionCode]=useState('');
  const [questionId,setQuestionId] = useState('');
  const [isLoading,setIsLoading] = useState(true);
  const [isError,setIsError] = useState(false);
  const [errorMsg,setErrorMsg] = useState('');
  const [show, setShow] = useState(false);
  const [sessionId,setSessionId] = useState('');
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [showEdit,setShowEdit] = useState(false);
  const handleEditClose = () => setShowEdit(false);
  const handleEditShow = () => setShowEdit(true);
  const [editData,setEditDate] = useState({});
  const [isAddedFlag,setIsAddedFlag] = useState(false);
  const [isEditFlag,setIsEditFlag] = useState(false);
  const [isDeleteFlag,setIsDeleteFlag] = useState(false);
  function changeAddedState(){
    setIsAddedFlag(!isAddedFlag);
  }
  function changeEditState(){
    setIsEditFlag(!isEditFlag);
  }
  function changeDeleteState(){
    setIsDeleteFlag(!isDeleteFlag);
  }
  useEffect(() => {
    //fetch data
    function fetchQuestions(url) {
      const urlObj = new URL(url);
      const params = new URLSearchParams(urlObj.search);
      const sessionId = params.get("session-id");
      setSessionId(sessionId);
      setSessionCode(params.get("session-code"));
      //todo
      reqInstance(apiurl+'/api/SessionQuestions/getquestionsbysessionid/'+sessionId).then((response)=>{
        console.log('response data',response.data)
        setQuestions(response.data)
        setIsLoading(false)
        // if(response.ok)
        //   throw new Error('invalid')
        // return response.json()
        }).then(response=>{
          // console.log('ddata',response.data)
          // setQuestions(response.data)
          // setIsLoading(false);
      })
      .catch(err=>{
        setIsError(true);
        setErrorMsg(err.message);
      })
    }
    fetchQuestions(window.location.href);
  }, [isAddedFlag,isEditFlag,isDeleteFlag]);
  if(isError){
    return (
      <h1 className="text-center">error... {errorMsg}</h1>
    )
  }
  return (
    <>
      <section className="questions-container">
        <ConfirmDelete
          show={modalDelete}
          onHide={() => setModalDelete(false)}
          id={questionId}
          refresh={changeDeleteState}
        />
        <header> Here are the questions, you can edit them ! <span className='bg-success'>{sessionCode}</span></header>
        <button onClick={handlePrint} className="btn btn-primary mx-1">
          print
        </button>
        <Button variant="primary" onClick={handleShow}>
        add
      </Button>
      {show?<AddModal show={handleShow} handleClose={handleClose} sessionId={sessionId} refresh={changeAddedState}></AddModal>:''}
      {showEdit?<EditModal show={handleEditShow} handleClose={handleEditClose} {...editData} sessionId={sessionId} refresh={changeEditState}></EditModal>:''}
      {isLoading?<h1 className="text-center">Loading...</h1>:
      <section className="questions text-primary" ref={printable}>
          {questions.map((ques,index) => {
            const {
              id,
              question,
              choice1,
              choice2,
              choice3,
              choice4,
              answerOption,
            } = ques;
            return (
              <article key={id}>
                <p>Q{index+1} {question}</p>
                <p>a: {choice1}</p>
                <p>b: {choice2}</p>
                <p>c: {choice3}</p>
                <p>d: {choice4}</p>
                <p className="answer">
                  answer: <span>{answerOption}</span>
                </p>
                <aside className="actions">
                  <button className="btn btn-primary mx-1" onClick={()=>{
                    setEditDate(ques);
                    handleEditShow()
                  }}>
                    <img src={Pencil} alt="edit" />
                  </button>
                  <Button
                    variant="primary"
                    onClick={() => {setModalDelete(true);setQuestionId(id)}}
                  >
                    <img src={Trash} alt="delete" />
                  </Button>
                </aside>
              </article>
            );
          })}
        </section>}
        
        
      </section>
    </>
  );
};

export default PreviewQuestions;
