import React from 'react'
import { Link } from "react-router-dom";
const CancelPayment = () => {
  return (
    <>
    <div>Your payment was unsuccessful.</div>
    <Link to="/buy-now">
        <div className='btn btn-primary'>retry</div>
    </Link>
    </>
  )
}

export default CancelPayment