import { createContext, useContext, useState } from 'react';

const AppContext = createContext();

export const AppProvider = ({ children }) => {
  const [sessionDetails, setSessionDetails] = useState(null); // Set your initial state here
  const [question, setQuesion] = useState({});
  const [currentsessionuser,setCurrentSessionUser] = useState({});

  const updateCurrentSessionUser = (newState) => {
    setCurrentSessionUser((prevState) => ({ ...prevState, ...newState }));   
  };

  const updateSessionDetails = (newState) => {
    setSessionDetails((prevState) => ({ ...prevState, ...newState }));
   
  };
  const updateQuestion = (newState) => {
    setQuesion((prevState) => ({ ...prevState, ...newState }));
  };

  return (
    <AppContext.Provider value={{ sessionDetails, updateSessionDetails, question, updateQuestion, currentsessionuser, updateCurrentSessionUser }}>
      {children}
    </AppContext.Provider>
  );
};
export const useAppContext = () => useContext(AppContext);
