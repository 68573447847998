import Card from "react-bootstrap/Card";
import one from "../images/one.png";
import two from "../images/two.png";
import three from "../images/three.png";
import four from "../images/four.png";
import { useNavigate } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import { websiteurl,STRIPE_KEY } from "../globals";


const stripePromise = loadStripe(
  STRIPE_KEY
  // "pk_test_51OCYGaGJVWLloJD8soM19joLgxc7U02JP73BXJMbDt2bWsfol8TDz9aD14Wjrz3gZscwRb06roU33prUgZS1Pft90077MaoY6Q"
  // "pk_test_51OBFMQGfcmrCb7AIoRU1qV0KUGnR1PaSJfLn911qtFXSgHutN8gRfd9iHLyn2ZjfP85YtBCso4MrESJtyLkOJo3z00rWconnCv"
);
function BuyNow() {
  // Define arrays for different content
  const variants = ["Primary", "Success", "Danger", "Warning"];
  const headerTitles = [
    "Bronze",
    "Silver",
    "Gold",
    "Platinum",
  ];
  const cardTitles = [
    "50 questions 2 players max. Expires in 1 year",
    "100 questions 5 players max. Expires in 1 year" ,
    "250 questions 15 players max. Expires in 1 year",
    "1000 questions 100 players max. Expires in 1 year",
  ];
  const cardTexts = [
    "Play 50 questions maximum. You can play any number of times. Good for individual who wants to try our specific speciality exams questions.",
    "Play 100 questions maximum. You can play any number of times. In any one game you can play with 5 members same time.",
    "Good for a group or corporate employee's to build the team spirit and relax. You can play educational games or fun quiz games.",
    "1000 questions in any topic. Good for people who is serious in passing specific exam, certifications etc., You can also play fun or educational quiz games",
  ];

  const prices = ["1", "2", "5", "10"];
  const priceIds = [
    // "price_1OG1drGJVWLloJD87zN3fLxV",   
    // "price_1OG1hgGJVWLloJD8rT4vO5xN", 
    // "price_1OG1i1GJVWLloJD8MmzHobdQ",  
    // "price_1OG1iKGJVWLloJD8mFoK9DPU"    
    
    // "price_1OdloxGJVWLloJD8y729I9yQ",
    // "price_1OdlnmGJVWLloJD81z5dnogD",
    // "price_1OG1i1GJVWLloJD8MmzHobdQ",
    // "price_1OG1iKGJVWLloJD8mFoK9DPU"

    "price_1Pe8N9GJVWLloJD8VaYEhKhH",
    "price_1Pe8NCGJVWLloJD8s70m09aw",
    "price_1Pe8NfGJVWLloJD8qx5oRZD5",
    "price_1Pe8NKGJVWLloJD8etsjRL6t"

  ];

  const buttonLabels = ["Buy Now", "Buy Now", "Buy Now", "Buy Now"];
  const imageSources = [one, two, three, four];
  const navigate = useNavigate();

  const handleClick = async (price, title, priceId) => {
    // console.log(checkout)
    const stripe = await stripePromise;
    console.log(stripe)
    const result = await stripe.redirectToCheckout({
      // customerEmail: emailId,
      // payment_method_types: ['card'],
      lineItems: [
        {
          price: priceId,
          quantity: 1,
        },
      ],
      mode: "subscription",
      successUrl: `${websiteurl}/successfulPayment?session_id={CHECKOUT_SESSION_ID}`,
      cancelUrl: `${websiteurl}/cancelPayment`,
    });

    if (result.error) {
      console.error("Error during redirect to checkout:", result.error);
    } else {
      console.log("Payment Intent details:", result);

      // Log other data to the console
      console.log("Payment details:", {
        selectPlane: title,
        paidAmount: parseInt(price),
        paymentConfirmation: priceId,
      });

      // Call the function for submitting payment details
      submitPaymentSuccessful();
    }

    // After Payment Successful Success

    const submitPaymentSuccessful = async () => {

      //todo
  //     reqInstance.post(`${apiurl}/api/planinfo`, updateData)
  //     .then(response => {
  //       // Handle the API response
  //       console.log(response.data);
     
        
  //     })
  //     .catch(error => {          
  //       // Handle any error that occurred during the API call
  //       console.error('Error:', error);     
    
  //     });
  // };

      // const response = await axios.post(
      //   "http://localhost:4000/create-payment",
      //   {
      //     // emailId: emailId, //?
      //     selectPlane: title, // plane
      //     paidAmount: parseInt(price), // amount
      //     paymentConfirmation: priceId, // priceId
      //   }
      // );
    };
  };

  return (
    <div
      className="d-flex justify-content-evenly align-items-center flex-wrap"
      // style={{ height: "100vh" }}
    >
      {variants.map((variant, index) => (
        <Card
          bg={variant.toLowerCase()}
          key={variant}
          text={variant.toLowerCase() === "light" ? "dark" : "white"}
          style={{ width: "18rem", height: "90vh", marginTop: "20px" }} // Fixed width and height
          className="m-2"
        >
          <Card.Header>{headerTitles[index]}</Card.Header>
          <Card.Body className="bg-white p-0">
            <div className="position-relative">
              <Card.Img
                variant="top"
                src={imageSources[index]}
                style={{
                  width: "100%",
                  height: "180px",
                  borderBottomLeftRadius: "50%",
                  borderBottomRightRadius: "50%",
                }}
              />
              <div
                className="position-absolute w-100"
                style={{
                  bottom: 0,
                  height: "50px",
                  background: "linear-gradient(to bottom, transparent, #ccc)",
                  zIndex: 2,
                }}
              ></div>
              <div
                className="position-absolute w-100"
                style={{
                  bottom: 0,
                  height: "2px",
                  background: "#888",
                  zIndex: 3,
                }}
              ></div>
            </div>
            <Card.Title className="text-dark p-2">
              {cardTitles[index]}
            </Card.Title>
            <Card.Text className="text-dark p-2">{cardTexts[index]}</Card.Text>
            <Card.Title className="text-dark p-2">{`${prices[index]}$`}</Card.Title>
          </Card.Body>
          <Card.Footer className="bg-white">
            <button
              className="btn btn-success"
              onClick={() =>
                handleClick(prices[index], headerTitles[index], priceIds[index])
              }
            >
              {buttonLabels[index]}
            </button>
          </Card.Footer>
        </Card>
      ))}
    </div>
  );
}

export default BuyNow;
