import React, { useState, useEffect } from 'react';
import { reqInstance, apiurl } from '../globals';
import Quiz  from './Quiz';
import Result from './Result';
import { useAppContext } from '../AppContext';
import { useNavigate } from 'react-router-dom';
 
const ShowQuizAndResults = () => {     
    const [question, setQuesion] = useState({});  
    const [showQuiz, setShowQuiz] = useState(true);
    const { sessionDetails } = useAppContext();
    const [currentQuestion,setCurrentQuestion]= useState(1);
    const {navigation} = useNavigate();
    
    const getQuestion = (questionNo) => {
        
        reqInstance.get( `${apiurl}/api/sessionQuestions/${sessionDetails.id}/${questionNo}`)        
        .then(response => {            
           setQuesion(response.data);
          //startQuiz(response.data,currentsessionuser.userEmailId,currentsessionuser.userName);
        })
        .catch(error => {
          console.log('Error:', error);
        });
    }


    const showResult = () =>
    {   
        
        setShowQuiz(false); 
                       
    }

    const showNextQuestion = () => {
        if (currentQuestion + 1 > sessionDetails.noOfQuestions)
        { navigation('/');}

        setCurrentQuestion(currentQuestion + 1);        
    }

    useEffect(() => {       
        getQuestion(1);             
      }, []);

    useEffect(() =>{
       
        if (currentQuestion <= sessionDetails.noOfQuestions)      {
            getQuestion(currentQuestion);
            setShowQuiz(true);  }
        else
            {
               
                setShowQuiz(false);                
            }        
    },[currentQuestion]);

     return (
        (showQuiz ? (<Quiz question={question} setShowQuiz={setShowQuiz} showResult={showResult}  ></Quiz> ):
        ( <Result currentQuestion={currentQuestion} showNextQuestion={showNextQuestion} ></Result>) )
    );
};

export default ShowQuizAndResults;