import React, { useEffect, useState } from 'react';
import * as stripe from "@stripe/stripe-js";
import {STRIPE_KEY} from './../globals';
const SuccessfulPayment = () => {
  const [customerName, setCustomerName] = useState('');
  const stripePromise = stripe.loadStripe(
    STRIPE_KEY
    // "pk_test_51OCYGaGJVWLloJD8soM19joLgxc7U02JP73BXJMbDt2bWsfol8TDz9aD14Wjrz3gZscwRb06roU33prUgZS1Pft90077MaoY6Q"
    // "pk_test_51OBFMQGfcmrCb7AIoRU1qV0KUGnR1PaSJfLn911qtFXSgHutN8gRfd9iHLyn2ZjfP85YtBCso4MrESJtyLkOJo3z00rWconnCv"
  )
  async function getData(sessionId){
      // const session = await stripePromise.checkout.sessions.retrieve(
      //   sessionId
      // );
      // const session = await stripe.checkout.sessions.retrieve(sessionId)
      const session = await stripePromise
      const getUserData = await session.checkout
      console.log(getUserData)
    }
  useEffect(() => {
    // Assuming you have an endpoint '/api/order/success' that takes a session_id query parameter
    const sessionId = new URLSearchParams(window.location.search).get('session_id');
    // axios.get(`/api/checkout-session?session_id=${sessionId}`)
    //   .then(response => {
    //     console.log(response)
    //     // setSession(response.data);
    //   })
    //   .catch(error => {
    //     console.error('Error fetching checkout session:', error);
    //   });
    
    getData(sessionId);
  }, []);

  return (
    <div>
      <h1>Thanks for your order, {customerName}!</h1>
    </div>
  );
};

export default SuccessfulPayment;
