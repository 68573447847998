import React, { useState, useEffect } from "react";
import { reqInstance, apiurl } from "../globals";
import { useAppContext } from "../AppContext";

const Quiz = ({ question, showResult }) => {
  const [seconds, setSeconds] = useState(0);
  const [correctAnswer, setcorrectAnswer] = useState(0);
  const { currentsessionuser } = useAppContext();

  // Set a Single Question
  useEffect(() => {
    setSeconds(0);
    const intervalId = setInterval(() => {
      setSeconds((prevSeconds) => prevSeconds + 1);
    }, 2000); // Update every second (1000ms)
  }, [question]);

  const delayAndCallFunction = () => {
    setTimeout(() => {
      // Call your function here

      showResult();
      //showTheResult(topscorers,sessionUser);
    }, 1000); // 1000 milliseconds = 1 second
  };

  const updateMarks = (marks, question, sessionUser) => {
    //Call api to update the user answer to session user table.
    reqInstance
      .post(
        `${apiurl}/api/SessionUserScores/${question.sessionId}/${sessionUser.userEmailId}/${marks}/${question.sequenceNo}`
      )
      .then((response) => {
        console.log("delay ", response.data);

        delayAndCallFunction();
      })
      .catch((error) => {
        // Handle any error that occurred during the API call
        console.error("Error:", error);
      });
  };
  // Check Answer
  const checkAnswer = (event, selected, seconds, question, sessionUser) => {
    //Call api to update the user answer to session user table.
    reqInstance
      .get(
        `${apiurl}/api/SessionQuestions/checkanswer/${question.sessionId}/${question.id}`
      )
      .then((response) => {
        console.log(response.data);

        setcorrectAnswer(response.data.answerOption);

        if (Number(selected) === Number(response.data.answerOption)) {
          event.target.classList.add("bg-success");
          updateMarks(1000 - seconds, question, sessionUser);
        } else {
          updateMarks(0, question, sessionUser);
          event.target.classList.add("bg-danger");
        }
      })
      .catch((error) => {
        // Handle any error that occurred during the API call
        console.error("Error:", error);
      });
  };
  // const getNextQuestion = () => {

  //     axios.get(`${apiurl}/api/SessionQuestions/${question.sessionId}/${question.sequenceNo + 1}`)
  //     .then(response => {
  //       // Handle the API response
  //       console.log(response.data);

  //       // if (showTheResult()){
  //       //   nextQuestion(response.data);
  //       // }

  //     })
  //     .catch(error => {
  //       // Handle any error that occurred during the API call
  //       console.error('Error:', error);
  //     });

  // };

  return (
    <section className="">
      <div className="container">
        <div className="row vh-100 align-items-center justify-content-center">
          <div className="col-lg-8">
            <div
              className="card p-4"
              style={{ background: "#3d3d3d", borderColor: "#646464" }}
            >
              <div className="d-flex justify-content-between gap-md-3">
                <h5 className="mb-2 fs-normal lh-base">{question.question}</h5>
                <h5>{seconds}</h5>
                {/* <h5 style={{ color: '#60d600', width: '100px', textAlign: 'right' }}>{question} / {quizs?.length}</h5> */}
              </div>
              <div>
                <button
                  key={1}
                  className={`option w-100 text-start btn text-white py-2 px-3 mt-3 rounded btn-dark ${
                    correctAnswer === 1 && "bg-success"
                  }`}
                  onClick={(event) =>
                    checkAnswer(event, 1, seconds, question, currentsessionuser)
                  }
                >
                  {question.choice1}
                </button>
                <button
                  key={2}
                  className={`option w-100 text-start btn text-white py-2 px-3 mt-3 rounded btn-dark ${
                    correctAnswer === 2 && "bg-success"
                  }`}
                  onClick={(event) =>
                    checkAnswer(event, 2, seconds, question, currentsessionuser)
                  }
                >
                  {question.choice2}
                </button>
                <button
                  key={3}
                  className={`option w-100 text-start btn text-white py-2 px-3 mt-3 rounded btn-dark ${
                    correctAnswer === 3 && "bg-success"
                  }`}
                  onClick={(event) =>
                    checkAnswer(event, 3, seconds, question, currentsessionuser)
                  }
                >
                  {question.choice3}
                </button>
                <button
                  key={4}
                  className={`option w-100 text-start btn text-white py-2 px-3 mt-3 rounded btn-dark ${
                    correctAnswer === 4 && "bg-success"
                  }`}
                  onClick={(event) =>
                    checkAnswer(event, 4, seconds, question, currentsessionuser)
                  }
                >
                  {question.choice4}
                </button>
              </div>

              {
                // (questionIndex + 1) !== quizs.length ?
                // <button className='btn py-2 w-100 mt-3 bg-primary text-light fw-bold' onClick={getNextQuestion} disabled={!selectedAnswer}>Next Question</button>
                // <button className='btn py-2 w-100 mt-3 bg-primary text-light fw-bold' onClick={showTheResult} disabled={!selectedAnswer}>Show Result</button>
              }
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Quiz;
