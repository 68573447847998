import React, { useState, useEffect } from 'react';
import { reqInstance, apiurl } from "../globals";
import { useNavigate } from "react-router-dom";

const PublicQuiz = () => {
  const [sessions, setSessions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const pageSize = 10;
  const [isError, setIsError] = useState(false);
  const [errorMsg, setErrorMsg] = useState('');
  const navigate = useNavigate();
  const fetchSessions = async (page) => {
    console.log("Fetching sessions for page:", page); // Debugging log

    try {
      const response = await reqInstance.get(`${apiurl}/api/SessionDetails/search`, {
        params: {
          keyword: 'PUBLIC',
          pageNumber: page,
          pageSize: pageSize
        }
      });
      
      if (response?.data) {
        const records = response.data.data;
        const totalPages = response.data.totalPages;

        setSessions(records);
        setTotalPages(totalPages); 
      } else {
        setIsError(true);
        setErrorMsg('No data found');
        setSessions([]); 
      }
    } catch (error) {
      console.error("Failed to fetch sessions:", error);
      setIsError(true);
      setErrorMsg("Failed to fetch sessions: " + error.message);
      setSessions([]); 
    }
  };

  useEffect(() => {
    fetchSessions(currentPage);
  }, [currentPage]); // Triggers fetchSessions every time currentPage changes

  const handlePageChange = (page) => {
    if (page > 0 && page <= totalPages) {
      setCurrentPage(page);
    }
  };

  const handlePlayQuiz =(sessioncode) =>
  {
    navigate(`/joinquizsession?sessionid=${sessioncode}`);       
  }

  return (
    <div>
      <h2>Quiz played by all</h2>
      {isError ? (
        <div className="error">{errorMsg}</div>
      ) : (
        <table style={{ width: "100%", borderCollapse: "collapse" }}>
          <thead>
            <tr>
              <th style={{ borderBottom: "1px solid #ddd", textAlign: "left", padding: "8px" }}>Code</th>
              <th style={{ borderBottom: "1px solid #ddd", textAlign: "left", padding: "8px" }}>Description</th>
              <th style={{ borderBottom: "1px solid #ddd", textAlign: "left", padding: "8px" }}>Action</th>
            </tr>
          </thead>
          <tbody>
            {sessions.map((session) => (
              <tr key={session.id}>
                <td style={{ borderBottom: "1px solid #ddd", padding: "8px", textAlign: "left" }}>{session.sessionCode}</td>
                <td style={{ borderBottom: "1px solid #ddd", padding: "8px", textAlign: "left" }}>{session.description}</td>
                <td style={{ borderBottom: "1px solid #ddd", padding: "8px", textAlign: "left" }}>
                  <button className="btn btn-primary mx-1" onClick={() => handlePlayQuiz(session.sessionCode)}>Play</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      )}
      
      <div className="pagination" style={{ marginTop: "20px" }}>
        <button onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
          Previous
        </button>
        <span>Page {currentPage} of {totalPages}</span>
        <button onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
          Next
        </button>
      </div>
    </div>
  );
};

export default PublicQuiz;
