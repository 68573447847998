import React, { useEffect, useState } from "react";
import { reqInstance, apiurl } from "../globals";
import { useAppContext } from "../AppContext";
import { useNavigate } from "react-router-dom";

const DeleteMyData = ({ currentQuestion, showNextQuestion, showFinalScreen }) => {
  const { sessionDetails } = useAppContext();
 
  const navigate = useNavigate();

  useEffect(() => {
   
  }, []);

  const getDeleteMyData = () => {
    if (window.confirm('Are you sure you wish to delete all the data?')) {
    reqInstance
      .get(`${apiurl}/api/SessionUsers/players/delete/${localStorage.getItem("emailId")}`)
      .then((response) => {
        window.close('Your data deleted successfully.')
        alert("Your data deleted successfully.")
      })
      .catch((error) => {
        console.log("Error:", error);
      });
    }
  };

  const gotoToMain = () => {
    navigate("/");
  };

  useEffect(() => {    
    console.log("sess data",sessionDetails);
  }, []);


  return   (
    <section className="bg-dark text-white">
      <div className="container">
        <div className="row vh-100 align-items-center justify-content-center">
          <div className="col-lg-6">
            <div
              className={`text-light text-center p-5 rounded ${
                (1 * 5) / 2 ? "bg-success" : "bg-danger"
              }`}
            >
              <h1 className="mb-2 fw-bold">
              </h1>
              <span className="bg-success rounded p-1">{localStorage.getItem("emailId")}</span>
              {/* <h3 className='mb-3 fw-bold'>Your score is {marks} out of {quizs.length * 5}</h3> */}
              <div>
              <button
                  key={4}
                  className={`option w-100 text-start btn text-white py-2 px-3 mt-3 rounded btn-dark                 
                  `}
                  onClick={(event) =>
                    getDeleteMyData()
                  }
                >                
                Click here to delete all my data
                </button>
              </div>
             </div>
          </div>
        </div>
      </div>
    </section>
  )};
 

export default DeleteMyData;
